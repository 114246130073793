import Cookies from 'js-cookie'

const TokenKey = 'vue_admin_template_token'
const S3Key = 'spe_s3_key'

// const InFourteenMinutes = new Date(new Date().getTime() + 14 * 60 * 1000)

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getS3Key() {
  return Cookies.get(S3Key)
}

export function setS3Key(data) {
  // return Cookies.set(S3Key, data, { expires: InFourteenMinutes })
  return Cookies.set(S3Key, data.data)
}